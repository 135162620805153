<template>
  <div class="min-h-screen bg-base-300 flex items-center justify-center ">
    <div class="inner bg-card-bg max-w-sm p-8 shadow-lg text-center rounded-2xl">
      <h1 class="text-4xl leading-snug   text-gray-700  capitalize font-bold"
        v-html="$t('not_found.not_found_title')">
      </h1>
      <p class="my-6">
        {{ $t('not_found.not_found_description') }}
      </p>
      <a href="/" class="btn btn-primary btn-block"> {{ $t('not_found.go_home') }} </a>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Not Found',

}
</script>

<style>

</style>