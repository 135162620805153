<!-- Localized -->
<template>
  <div class="w-full flex h-full justify-center items-center ">
      <div class=" flex bg-white shadow rounded-xl p-6 flex-col mx-auto justify-center items-center">
          <div class=" mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-red-500 w-20 h-20" viewBox="0 0 24 24" fill="none">
                <path xmlns="http://www.w3.org/2000/svg" id="Vector" d="M12 9.00006V13.0001M4.37891 15.1999C3.46947 16.775 3.01489 17.5629 3.08281 18.2092C3.14206 18.7729 3.43792 19.2851 3.89648 19.6182C4.42204 20.0001 5.3309 20.0001 7.14853 20.0001H16.8515C18.6691 20.0001 19.5778 20.0001 20.1034 19.6182C20.5619 19.2851 20.8579 18.7729 20.9172 18.2092C20.9851 17.5629 20.5307 16.775 19.6212 15.1999L14.7715 6.79986C13.8621 5.22468 13.4071 4.43722 12.8135 4.17291C12.2957 3.94236 11.704 3.94236 11.1862 4.17291C10.5928 4.43711 10.1381 5.22458 9.22946 6.79845L4.37891 15.1999ZM12.0508 16.0001V16.1001L11.9502 16.1003V16.0001H12.0508Z" stroke="currentColor " stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <h1 class="text-3xl mb-4 font-bold text-red-500">
            {{ $t('components.unauthenticated.access_denied') }}
          </h1>

          <p class="text-gray-500 text-center" >
            {{ $t('components.unauthenticated.sorry_but_you_dont_have_the_permission_to_access_this_page') }}
            <!-- <br> Contact you <strong>Admin</strong> -->
              <span v-if="goBackButton">
                <br>{{ $t('components.unauthenticated.you_can_go_back_to') }}<strong  @click="$router.go(-1)"  class="cursor-pointer text-primary">{{ $t('components.unauthenticated.previous_page') }}</strong>
              </span>  
          </p>

      </div>
  </div>
</template>

<script>
export default {
    props: {
        goBackButton: {
            type: Boolean,
            default: true,
        }
    }

}
</script>

<style>

</style>